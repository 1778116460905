import React, { Component } from 'react';
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Reveal from 'react-reveal/Reveal';
import {drawIn} from 'styles/animations';

const HeadingContainer = styled.h2`
  padding-top: 2.4rem;
  max-width: 80rem;
    
  &::before {
    content: "";
    display: block;
    position: absolute;
    right: calc(100% - 3.8rem);
    top: 0;
    width: 50vw;
    height: 0.4rem;
    background-color: ${colors.red600};
    transform: scaleX(0.6);
    animation-fill-mode: forwards;
  }
  
  .drawIn & {
    &::before {
      animation: ${drawIn} 0.5s cubic-bezier(0.22, 1, 0.36, 1) 0.1s forwards;
    }
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    padding-top: 0;
      
    &::before {
      top: 2.4rem;
      right: calc(100% + 1.6rem);
    }
  }
`;

class SectionHeading extends Component {
  render() {
    const { children, ...props } = this.props;

    return (
      <Reveal effect="drawIn">
        <HeadingContainer {...props}>
          {children}
        </HeadingContainer>
      </Reveal>
    );
  }
}

export default SectionHeading;
