import React, { Component } from 'react';
import { Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Caret from "vectors/caret-right.svg";

const LinkContainer = styled(Link)`
  display: inline-block;
  position: relative;
  padding: 0.8rem 7.2rem 0.8rem 0;
  
  span {
    color: ${colors.red600};
    font-size: 1.6rem;
    font-weight: 500;
  }
  
  .arrow-container {
    position: absolute;
    left: calc(100% - 4.8rem);
    // visual centering
    top: 42%;
    width: 4.8rem;
    height: 0.2rem;
    background-color: ${colors.red600};
    transition: width 0.12s ease-in-out;
  }
  
  svg {
    display: block;
    position: absolute;
    right: -0.2rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0.9rem;
    height: 1.7rem;
    fill: ${colors.red600};
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    &:hover {
      div {
        width: 8rem;
      }
    }
  
    span {
      font-size: 1.8rem;
    }
    
    div {
      // visual centering
      top: 44%;
    }
  }
`;

const ExternalContainer = styled.a`
  display: inline-block;
  position: relative;
  padding: 0.8rem 7.2rem 0.8rem 0;
  
  span {
    color: ${colors.red600};
    font-size: 1.6rem;
    font-weight: 500;
  }
  
  div {
    position: absolute;
    left: calc(100% - 4.8rem);
    // visual centering
    top: 42%;
    width: 4.8rem;
    height: 0.2rem;
    background-color: ${colors.red600};
    transition: width 0.12s ease-in-out;
  }
  
  svg {
    display: block;
    position: absolute;
    right: -0.2rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0.9rem;
    height: 1.7rem;
    fill: ${colors.red600};
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    &:hover {
      div {
        width: 8rem;
      }
    }
  
    span {
      font-size: 1.8rem;
    }
    
    div {
      // visual centering
      top: 44%;
    }
  }
`;

class ArrowLink extends Component {
  render() {
    const { text, external, ...props } = this.props;

    if (external) {
      return (
        <ExternalContainer {...props}>
          <span>{text}</span>

          <div className="arrow-container">
            <Caret/>
          </div>
        </ExternalContainer>
      );
    } else {
      return (
        <LinkContainer {...props}>
          <span>{text}</span>

          <div className="arrow-container">
            <Caret/>
          </div>
        </LinkContainer>
      );
    }
  }
}

export default ArrowLink;
