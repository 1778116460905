import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';


const RoadblockContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: 50vh;
    max-height: 36rem;
    object-fit: cover;
  }
  
  span {
    display: block;
    margin-top: 0.8rem;
    color: ${colors.grey800};
    font-size: 1.2rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    img {
      height: 72vh;
      max-height: 64rem;
    }
    
    span {
      margin-top: 1.6rem;
      font-size: 1.4rem;
    }
  }
`;

class CaseStudyRoadblock extends Component {
  render() {
    return (
      <RoadblockContainer>
        {this.props.image && (
          <img
            src={this.props.image.url}
            alt={this.props.image.alt}
          />
        )}

        {this.props.caption &&
          <Inner>
            <span>{this.props.caption}</span>
          </Inner>
        }
      </RoadblockContainer>
    );
  }
}

export default CaseStudyRoadblock;
