import React, { Component } from 'react';
import styled from '@emotion/styled';
import Content from 'components/_ui/Content';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';

const FeaturedInContainer = styled(Inner)`
  & + * {
    margin-top: 11.2rem;
  }
  
  &:last-of-type {
    margin-bottom: 11.2rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    
    & + * {
      margin-top: 12.4rem;
    }
    
    &:last-of-type {
      margin-bottom: 12.4rem;
    }
  }
`;

const FeaturedInContent = styled.div`
  & + * {
    margin-top: 8rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 62.4rem;
    
    & + * {
      margin-top: 7.2rem;
      margin-left: 7.2vw;
    }
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    & + * {
      margin-left: 18.8rem;
    }
  }
`;

const FeaturedInBlock = styled.div`
  background-color: #000;
  color: #fff;
  padding: 4rem 2.4rem 6.4rem;
  
  &::before {
    content: 'Featured In';
    display: block;
    margin-bottom: 3.2rem;
    font-size: 1.8rem;
    font-weight: 600;
    
  }
  
  li {
    & + * {
      margin-top: 4rem;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 0 1 55.2rem;
    padding: 5.6rem 4.8rem 7.2rem;
    
    li {

    }
  }
`;

const FeatureLink = styled.a`
  display: block;
  
  span {
    color: ${colors.red600};
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: underline;
  }
  
  h4 {
    color: #fff;
    font-weight: 400;
  }
`;

class FeaturedIn extends Component {
  render() {
    return (
      <FeaturedInContainer>
        <FeaturedInContent>
          {this.props.content && <Content content={this.props.content} />}
        </FeaturedInContent>

        <FeaturedInBlock>
          <ul>
            {this.props.articles.map((article, i) => {
              if (article.link) {
                return (
                  <li key={i}>
                    <FeatureLink
                      href={article.link.url}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {article.source && <span>{article.source}</span>}
                      {article.title && <h4>{article.title}</h4>}
                    </FeatureLink>
                  </li>
                );
              }

              return null;
            })}
          </ul>
        </FeaturedInBlock>
      </FeaturedInContainer>
    );
  }
}

export default FeaturedIn;
