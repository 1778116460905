import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner, gridColumns } from "styles/structure";
import Content from 'components/_ui/Content';

const ContentBlockContainer = styled.div`
  & + * {
    margin-top: 6.4rem;
  }
  
  &:last-of-type {
    margin-bottom: 6.4rem;
  }
   
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {    
    & + * {
      margin-top: 12rem;
    }
    
    &:last-of-type {
      margin-bottom: 12rem;
    }
  }
`;

const ContentBlockWrap = styled.div`
  max-width: 62.8rem;
  margin: 0 auto;
  
  > * {
    display: block;
    width: 100%;
    
    & + * {
      margin-top: 6.4rem;
    }
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
    max-width: 140rem;
    
    > * {
      ${gridColumns('1/2', 5.6)}
      
      & + * {
        margin-top: 0;
        margin-left: 5.6rem;
      }
    }
  }
`;

const ContentBlockImage = styled.div`
  img {
    display: block;
    width: 100%;
    
    & + * {
      margin-top: 0.8rem;
    }
  }
  
  span {
    display: block;
    color: ${colors.grey800};
    font-size: 1.6rem; 
  }
`;

const Rubric = styled.span`
  display: block;
  color: ${colors.red600};
  font-size: 2rem;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 2.8rem;
  }
`;

class ContentBlock extends Component {
  render() {
    return (
      <ContentBlockContainer>
        <Inner>
          <ContentBlockWrap>
            <div>
              {this.props.rubric && <Rubric>{this.props.rubric}</Rubric>}
              {this.props.content && <Content content={this.props.content}/>}
            </div>

            {this.props.image && (
              <ContentBlockImage>
                <img src={this.props.image.url} alt={this.props.image.alt} />

                {this.props.caption && <span>{this.props.caption}</span>}
              </ContentBlockImage>
            )}
          </ContentBlockWrap>
        </Inner>
      </ContentBlockContainer>
    );
  }
}

export default ContentBlock;
