import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner, gridColumns } from "styles/structure";

const PhotoStripContainer = styled.div`
  & + * {
    margin-top: 8rem;
  }

  &:last-of-type {
    margin-bottom: 8rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 12rem;
    }  

    &:last-of-type {
      margin-bottom: 12rem;
    }
  }
`;

const ImageList = styled.ul`
  max-width: 48rem;
  margin: 0 auto;
  
  li {
    display: block;
    margin-bottom: 4rem;
  }
  
  p {
    color: ${colors.grey800};
    margin-top: 1.2rem;
  }

  img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    max-width: none;
    justify-content: center;
    align-items: center;
    
    li {
      ${gridColumns('1/3', 4)}
    
      margin-right: 4rem;
      
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
`;

class PhotoStrip extends Component {
  render() {
    return (
      <PhotoStripContainer>
        <Inner>
          <ImageList>
            {this.props.photos.map((photo, i) => (
              <li key={i}>
                {photo.image && <img src={photo.image.url} alt={photo.image.alt} />}
                {photo.caption && <p>{photo.caption}</p>}
              </li>
            ))}
          </ImageList>
        </Inner>
      </PhotoStripContainer>
    );
  }
}

export default PhotoStrip;
