import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import RichText from 'components/RichText';
import Slider from "react-slick";
import Arrow from "vectors/arrow-right.svg";

const SlideshowContainer = styled.div`
  .slick-slider {
    width: 100%;
  }
  
  .slick-slide {
    > div {
      position: relative;
      
      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }
    }
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    .slick-slider {
      flex: 0 0 50%;
      width: 50%;
    }
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    .slick-slider {
      flex: 0 0 60%;
      width: 60%;
    }
    
    .slick-slide {
      > div {
        &::before {
          padding-bottom: 66.6667%;
        }
      }
    }
  }
`;

const Slide = styled.div` 
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SlideshowWrap = styled.div`  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    justify-content: stretch;
  }
`;

const SlideContent = styled.div`
  background-color: #000;
  width: 100%;
  padding: 3.2rem 2.4rem;
  color: #fff;
  overflow: hidden;
  
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
    
    & + * {
      margin-top: 1.6rem;
    }
  }
  
  p {
    line-height: 1.55;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    flex: 1 1 40%;
    padding: 4rem 4.8rem;
  }
`;

const SlideNav = styled.nav`
  display: block;
  margin-bottom: 2.4rem;
  order: 2;
  flex-shrink: 0;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-bottom: 0;
    margin-top: 2.4rem;
  }
`;

const SlideArrow = styled.button`
  display: inline-block;
  padding: 0;
  appearance: none;
  cursor: pointer;
  
  svg {
    display: block;
    fill: #fff;
    width: 4.8rem;
    height: 1.8rem;
    transition: fill 0.12s ease-in-out;
  }
  
  & + * {
    margin-left: 5.6rem;
  }
  
  &:first-of-type {
    svg {
      transform-origin: center;
      transform: rotate(180deg);
    }
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    &:hover {
      svg {
        fill: ${colors.red600};
      }
    }
  }
`;

const SlideWrap = styled.div`  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 100%;
    overflow-y: scroll;
    width: calc(100% + 7.2rem);
    padding-right: 7.2rem;
  }
`;

class Slideshow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
    };
  }

  render() {
    const self = this;

    const settings = {
      slidesToShow: 1,
      dots: false,
      arrows: false,
      afterChange: index => {
        self.setState({index: index});
      }
    };

    return (
      <SlideshowContainer>
        <Inner>
          <SlideshowWrap>
            <Slider ref={slider => (this.slider = slider)} {...settings}>
              {self.props.slides.map((slide, i) => (
                <Slide key={i}>
                  <img src={slide.image.url} alt={slide.image.alt} />
                </Slide>
              ))}
            </Slider>

            <SlideContent>
              <SlideNav>
                <SlideArrow onClick={() => {this.slider.slickPrev()}}>
                  <Arrow/>
                </SlideArrow>

                <SlideArrow onClick={() => {this.slider.slickNext()}}>
                  <Arrow/>
                </SlideArrow>
              </SlideNav>

              <SlideWrap>
                {self.props.slides[self.state.index].heading && <RichText render={self.props.slides[self.state.index].heading} />}
                {self.props.slides[self.state.index].text && <p>{self.props.slides[self.state.index].text}</p>}
              </SlideWrap>
            </SlideContent>
          </SlideshowWrap>
        </Inner>
      </SlideshowContainer>
    );
  }
}

export default Slideshow;
