import React, { Component } from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import colors from "styles/colors";
import { Inner, Wrap } from 'styles/structure';
import Content from 'components/_ui/Content';
import SectionHeading from 'components/_ui/SectionHeading';

const HighlightContainer = styled(Inner)``;

const HighlightWrap = styled(Wrap)`
  h2 {
    font-weight: 400;
    max-width: 107.2rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    
    h2 {
      position: relative;
      flex: 2 1 40%;
      
      &:only-child {
        margin-left: 7.2vw;  
      }
    }
    
    div {
      flex: 1 1 60%;
    }
  }
`;

const HighlightContent = styled.div`
  margin-top: 2.4rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 6.4rem;
    margin-left: 6.4rem;
  }
`;

const Rubric = styled.span`
  display: block;
  color: ${colors.red600};
  font-size: 2rem;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 2.8rem;
  }
`;

class CaseStudyHighlight extends Component {
  render() {
    return (
      <HighlightContainer>
        <HighlightWrap>
          {this.props.callout && <SectionHeading>{this.props.callout}</SectionHeading>}

          {this.props.content &&
            <HighlightContent>
              {this.props.rubric && <Rubric>{this.props.rubric}</Rubric>}

              <Content content={this.props.content}/>
            </HighlightContent>
          }
        </HighlightWrap>
      </HighlightContainer>
    );
  }
}

export default CaseStudyHighlight;
