import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Inner } from "styles/structure";
import Content from 'components/_ui/Content';
import dimensions from "styles/dimensions";

const TextBlockContainer = styled.div``;

const TextBlockWrap = styled.div`
  > div {
    width: 100%;
    max-width: 62.8rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    .TextBlock--aligned & {
      display: flex;
      width: 100%;
      
      > div {
        flex: 0 0 auto;
      }
    }
    
    .is-left & {
      padding-left: 15.6rem;
    }
    
    .is-center & {
      justify-content: center;
    }
      
    .is-right & {
      justify-content: flex-end;
    }
  }
`;

class TextBlock extends Component {
  render() {
    return (
      <TextBlockContainer className={this.props.alignment ? `TextBlock--aligned is-${this.props.alignment.toLowerCase()}` : ''}>
        <Inner>
          <TextBlockWrap>
            {this.props.content && <Content content={this.props.content}/>}
          </TextBlockWrap>
        </Inner>
      </TextBlockContainer>
    );
  }
}

export default TextBlock;
