import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner, gridColumns } from 'styles/structure';
import IconQuote from '../../vectors/quote.svg';

const QuoteImageGridContainer = styled.div`
  & + * {
    margin-top: 6.4rem;
  }
  
  &:last-of-type {
    margin-bottom: 6.4rem;
  }
   
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {    
    & + * {
      margin-top: 12rem;
    }
    
    &:last-of-type {
      margin-bottom: 12rem;
    }
  }
`;

const QuoteImageGridWrap = styled.div`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
  }
`;

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  > * {
    position: relative;
    
    &:nth-of-type(2) {
      flex: 0 0 66.6667%;
      max-width: 100%;
    }
    
    &:nth-of-type(3),
    &:nth-of-type(4) {
      ${gridColumns('1/2', 0.8)}
      
      margin-top: 0.8rem;
      
      &::after {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 66.6667%;
      }
      
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    &:nth-of-type(4) {
      margin-left: 0.8rem;
    }
  }
  
  img {
    display: block;
    width: 100%;
  }
  
  & + * {
    margin-top: 6.4rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    justify-content: flex-end;  
    
    > * {
      &:nth-of-type(2) {
        ${gridColumns('5/12', 3.2)}
        
        margin-left: 3.2rem;
      }
      
      &:nth-of-type(3),
      &:nth-of-type(4) {
        ${gridColumns('1/2', 3.2)}
      
        margin-top: 3.2rem;
      }
      
      &:nth-of-type(4) {
        margin-left: 3.2rem;
      }
    }
  }
`;

const Quote = styled.div`
  svg {
    display: block;
    width: 4.2rem;
    height: 2.9rem;
    fill: ${colors.red600};
    margin-bottom: 1.6rem;
  }

  h4 {
    font-weight: 400;
    line-height: 1.44;
    margin-bottom: 2.4rem;
    max-width: 107.2rem;
  }

  h6 {
    color: ${colors.grey800};
    font-weight: 700;
  }

  span {
    color: ${colors.grey800};
    font-size: 1.6rem;
  }
  
  &.Quote--desktop {
    display: none;
  }
    
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 0;
    
    h4 {
      margin-bottom: 3.2rem;
    }

    svg {
      width: 6.8rem;
      height: 4.7rem;
      margin-bottom: 3.2rem;
    }
    
    &.Quote--mobile {
      display: none;
    }
    
    &.Quote--desktop {
      ${gridColumns('7/12', 3.2)}
    
      display: block;
      padding: 0 8vw;
    }
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    &.Quote--desktop {
      padding: 0 12rem;
    }
  }
`;

class QuoteImageGridUniform extends Component {
  render() {
    return (
      <QuoteImageGridContainer>
        <Inner>
          <QuoteImageGridWrap>
            <ImageGrid>
              <Quote className='Quote--desktop'>
                <IconQuote />

                <h4>{this.props.quote.content}</h4>

                <h6>{this.props.quote.name}</h6>

                {this.props.quote.title && <span>{this.props.quote.title}</span>}
              </Quote>

              {this.props.images.slice(0,3).map((field, i) => (
                <div>
                  <img key={i} src={field.image.url} alt={field.image.alt} />
                </div>
              ))}
            </ImageGrid>

            <Quote className='Quote--mobile'>
              <IconQuote />

              <h4>{this.props.quote.content}</h4>

              <h6>{this.props.quote.name}</h6>

              {this.props.quote.title && <span>{this.props.quote.title}</span>}
            </Quote>
          </QuoteImageGridWrap>
        </Inner>
      </QuoteImageGridContainer>
    );
  }
}

export default QuoteImageGridUniform;
