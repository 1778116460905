import React, { Component } from 'react';
import styled from '@emotion/styled';

const CustomHTMLContainer = styled.div`
  overflow: hidden;
`;

const CustomHTMLContent = styled.div`
  position: relative;
  
  iframe, 
  embed {
    max-width: 100%; 
  }
`;

class CustomHTML extends Component {
  render() {
    return (
      <CustomHTMLContainer>
        <CustomHTMLContent dangerouslySetInnerHTML={{__html: this.props.content[0].text}}/>
      </CustomHTMLContainer>
    );
  }
}

export default CustomHTML;
