import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import IconQuote from "../vectors/quote.svg";

const QuoteContainer = styled.div`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    .Quote--is-dark & {
      background-color: #000;
    }
  }

  ${({ work }) =>
    work &&
    `
    height: 100%;
    
  `}
`;

const QuoteContent = styled.div`
  padding: 6.4rem 3.2rem;
  background-color: ${colors.grey100};

  svg {
    display: block;
    width: 4.2rem;
    height: 2.9rem;
    fill: ${colors.red600};
    margin-bottom: 1.6rem;
  }

  /* Quote text */
  h4 {
    font-weight: 400;
    line-height: 1.44;
    margin-bottom: 2.4rem;
    max-width: 107.2rem;
  }

  /* Quote attribution */
  h6 {
    color: ${colors.grey800};
    font-weight: 700;
  }

  span {
    color: ${colors.grey800};
    font-size: 1.6rem;
  }

  .Quote--is-dark & {
    background-color: transparent;

    h4,
    h6,
    span {
      color: #fff;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 4.8rem 8.33vw 6.4rem;

    h4 {
      margin-bottom: 3.2rem;
    }

    svg {
      width: 6.8rem;
      height: 4.7rem;
      margin-bottom: 3.2rem;
    }
  }

  .Quote--work & {
    position: relative;
    grid-row: 1;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    /* margin-top: -2rem; */
    padding: 4rem 2rem 8rem 2rem;


    h4 {
      font-size: 1.6rem;
      color: black;
      line-height: 2.8rem;
    }

    svg {
      width: 4rem;
      height: 4rem;
      margin-bottom: 3.2rem;
      transform: translateY(1.6rem);
    }
  }
`;

const QuoteInner = styled(Inner)`
  .Quote--work & {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    @media (min-width: ${dimensions.tabletLandscapeUp}px) {
      padding-top: 6rem;
    }
  }
`;

class Quote extends Component {
  render() {
    const { quote, variant } = this.props;

    return (
      <>
        <QuoteContainer
          className={variant ? `Quote--${variant}` : ""}
          work={variant === "work"}
        >
          <QuoteInner slideStyle={this.props.slideStyle}>
            <QuoteContent>
              <IconQuote />

              {quote.content && <h4>{quote.content}</h4>}

              {quote.name && <h6>{quote.name}</h6>}

              {quote.title && <span>{quote.title}</span>}
            </QuoteContent>
          </QuoteInner>
        </QuoteContainer>
      </>
    );
  }
}

export default Quote;
