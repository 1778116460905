import React from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import ContentBlock from 'components/_slice/ContentBlock';
import QuoteImageGridJustified from 'components/_slice/QuoteImageGridJustified';
import QuoteImageGridUniform from 'components/_slice/QuoteImageGridUniform';
import CaseStudyHighlight from 'components/_page/case-study/Highlight';
import CaseStudyRoadblock from 'components/_page/case-study/Roadblock';
import Slideshow from 'components/_slice/Slideshow';
import PhotoStrip from 'components/_slice/PhotoStrip';
import FeaturedIn from 'components/_page/case-study/FeaturedIn';
import Quote from 'components/Quote';
import Content from 'components/_ui/Content';
import TextBlock from 'components/_slice/TextBlock';
import BlockImage from 'components/_slice/BlockImage';
import CustomHTML from 'components/_slice/CustomHTML';

const SliceZoneContainer = styled.div`  
  > * {
    & + * {
      margin-top: 6.4rem;
    }
    
    &:last-of-type {
      margin-bottom: 6.4rem;
    }
  }
   
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {    
    > * {
      & + * {
        margin-top: 10.4rem;
      }
      
      &:last-of-type {
        margin-bottom: 10.4rem;
      }
    }
  }
`;

const SliceZone = ({ body, variant }) => {
  return (
    <SliceZoneContainer>
      {body.map((slice, i) => {
        switch(slice.type) {
          case 'content_block':
            return (
              <ContentBlock
                key={i}
                content={slice.primary.content}
                image={slice.primary.image}
                rubric={slice.primary.rubric}
                caption={slice.primary.caption}
              />
            );

          case 'contentblock':
            return (
              <ContentBlock
                key={i}
                content={slice.primary.content}
                image={slice.primary.image}
                rubric={slice.primary.rubric}
                caption={slice.primary.caption}
              />
            );

          case 'image':
            return (
              <ContentBlock
                key={i}
                content={slice.primary.content}
                image={slice.primary.image}
              />
            );

          case 'text':
            if (variant === 'post') {
              return (
                <Content
                  key={i}
                  content={slice.primary.content}
                />
              );

            } else {
              return (
                <TextBlock
                  key={i}
                  content={slice.primary.content}
                  alignment={slice.primary.alignment}
                />
              );
            }

          case 'quote_image_grid':
            if (slice.primary.style === 'Justified') {
              return (
                <QuoteImageGridJustified
                  key={i}
                  quote={slice.primary.quote}
                  images={slice.fields.slice(0,3)}
                />
              );
            } else if (slice.primary.style === 'Uniform') {
              return (
                <QuoteImageGridUniform
                  key={i}
                  quote={slice.primary.quote}
                  images={slice.fields.slice(0,3)}
                />
              );
            }

            return null;

          case 'callout':
            return (
              <CaseStudyHighlight
                key={i}
                callout={slice.primary.callout}
                content={slice.primary.content}
                rubric={slice.primary.rubric}
              />
            );

          case 'highlight':
            return (
              <CaseStudyHighlight
                key={i}
                callout={slice.primary.callout}
                content={slice.primary.content}
                rubric={slice.primary.rubric}
              />
            );

          case 'roadblock':
            return (
              <CaseStudyRoadblock
                image={slice.primary.image}
                caption={slice.primary.caption}
              />
            );

          case 'slideshow':
            return (
              <Slideshow
                key={i}
                slides={slice.fields}
              />
            );

          case 'photo_strip':
            return (
              <PhotoStrip
                key={i}
                photos={slice.fields}
              />
            );

          case 'featured':
            return (
              <FeaturedIn
                key={i}
                content={slice.primary.content}
                articles={slice.fields}
              />
            );

          case 'quote':
            return (
              <Quote
                key={i}
                quote={slice.primary.quote}
              />
            );

          case 'block_image':
            return (
              <BlockImage
                key={i}
                image={slice.primary.image}
                caption={slice.primary.caption}
                credit={slice.primary.credit}
              />
            );

          case 'custom_html':
            return (
              <CustomHTML
                key={i}
                content={slice.primary.content}
              />
            );
          default:
            break;
        }

        return null;
      })}
    </SliceZoneContainer>
  );
};

export default SliceZone;
