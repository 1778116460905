import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import AboutHeader from 'components/_page/about/Header';
import SliceZone from 'components/SliceZone';
import EmployeeSpotlight from 'components/_page/about/EmployeeSpotlight';
import SocialStrip from 'components/_page/about/SocialStrip';
import Related from 'components/Related';

const RenderBody = ({ culture, history, careers }) => (
  <>
    <AboutHeader
      title='Culture'
      heading={culture.introduction_heading}
      content={culture.introduction_content}
      parallax={[-80, 100]}
    />

    <SliceZone body={culture.body} />

    {culture.spotlight && (
      <EmployeeSpotlight
        name={culture.spotlight.name}
        title={culture.spotlight.title}
        image={culture.spotlight.image}
        bio={culture.spotlight.short_bio}
      />
    )}

    {culture.featured_social_strip.length > 0 && (
      <SocialStrip
        content={culture.featured_social_content}
        images={culture.featured_social_strip}
      />
    )}

    <Related
      heading='Learn more about us'
      type='trapdoor'
      cards={[
        {
          destination: '/history',
          image: history.feature_image,
          name: 'History',
          description: history.short_description,
        },
        {
          destination: '/careers',
          image: careers.feature_image,
          name: 'Careers',
          description: careers.short_description,
        },
      ]}
    />
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const culture = data.prismic.allCultures.edges[0].node;
  const history = data.prismic.allHistorys.edges[0].node;
  const careers = data.prismic.allCareerss.edges[0].node;

  if (!culture) return null;

  return (
    <Layout>
      <SEO
        title={culture.social_title || 'Culture'}
        description={culture.social_description ? culture.social_description : null}
        image={culture.social_image ? culture.social_image.url : null}
      />

      <RenderBody culture={culture} history={history} careers={careers} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allCultures {
        edges {
          node {
            introduction_heading
            introduction_content
            spotlight {
              ... on PRISMIC_Person {
                name
                title
                image
                short_bio
              }
            }
            featured_social_content
            featured_social_strip {
              image
              external_url
            }
            body {
              ... on PRISMIC_CultureBodyContentblock {
                type
                primary {
                  content
                  image
                }
              }
              ... on PRISMIC_CultureBodyQuote_image_grid {
                type
                primary {
                  quote {
                    ... on PRISMIC_Quote {
                      content
                      name
                      title
                    }
                  }
                  style
                }
                fields {
                  image
                }
              }
            }
            social_title
            social_description
            social_image
          }
        }
      }
      allCareerss {
        edges {
          node {
            feature_image
            short_description
          }
        }
      }
      allHistorys {
        edges {
          node {
            feature_image
            short_description
          }
        }
      }
    }
  }
`;
