import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import RichText from 'components/RichText';

const SocialStripContainer = styled.div`
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {}
`;

const SocialStripInner = styled(Inner)`  
  p {
    font-size: 2rem;
    font-weight: 600;
  }
  
  a {
    color: ${colors.red600};
    text-decoration: none;
  }
  
  & + * {
    margin-top: 3.2rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    > div {
      padding-left: 7.7vw;
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    > div {
      padding-left: 11.2rem;
    }
  }
`;

const SocialStripLinks = styled.ul`
  li {
    display: none;
    position: relative;
    width: 100%;
    overflow: hidden;
    
    &:first-of-type {
      display: block;
    }
    
    &::after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;  
    }
    
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
      transition: transform 0.24s ease-in-out;
      transform-origin: center;
    }
  }
  
  a {
    display: block;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    
    li {
      display: block;
      width: 25%;
      flex: 0 0 25%;
    }
    
    a {
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
`;

class SocialStrip extends Component {
  render() {
    return (
      <SocialStripContainer>
        {this.props.content && (
          <SocialStripInner>
            <div>
              <RichText render={this.props.content} />
            </div>
          </SocialStripInner>
        )}

        <SocialStripLinks>
          {this.props.images.map((field, i) => (
            <li key={i}>
              <a
                href={field.external_url}
                target='_blank'
                rel="noopener noreferrer"
              >
                <img src={field.image.url} alt={field.image.alt} />
              </a>
            </li>
          ))}
        </SocialStripLinks>
      </SocialStripContainer>
    );
  }
}

export default SocialStrip;
