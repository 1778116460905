import React from "react";
import styled from "@emotion/styled";
import dimensions from 'styles/dimensions';
import colors from "styles/colors";
import Camera from 'vectors/camera.svg';

const BlockImageContainer = styled.div`
  margin-top: 5.6rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 6.4rem;
  }
`;

const BlockImageImage = styled.div`
  position: relative;
  
  img {
    display: block;
    width: 100%;
  }
  
  & + * {
    margin-top: 2.4rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {    
    img {
      bottom: 1.6rem;
    }
  }
`;

const BlockImageMeta = styled.div``;

const BlockImageCaption = styled.div`
  max-width: 49.6rem;
  color: ${colors.grey800};
  font-size: 1.4rem;
  
  & + * {
    margin-top: 1.2rem;
  }
`;

const BlockImageCredit = styled.div`
  position: relative;
  padding-left: 3.2rem;
  
  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: ${colors.grey600};
    width: 1.9rem;
    height: 1.4rem;
  }
  
  span {
    color: ${colors.grey600};
    font-size: 1.4rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    
  }
`;

const BlockImage = ({ image, caption, credit }) => (
  <BlockImageContainer>
    {image && (
      <BlockImageImage>
        <img src={image.url} alt={image.alt}/>
      </BlockImageImage>
    )}

    {(caption || credit) && (
      <BlockImageMeta>
        {caption && (
          <BlockImageCaption>{caption}</BlockImageCaption>
        )}

        {credit && (
          <BlockImageCredit>
            <Camera/>
            <span>{credit}</span>
          </BlockImageCredit>
        )}
      </BlockImageMeta>
    )}
  </BlockImageContainer>
);

export default BlockImage;
