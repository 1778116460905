import React, { Component } from "react";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import ArrowLink from "components/_ui/ArrowLink";

const SpotlightContainer = styled.div`
  & + * {
    margin-top: 6.4rem;
  }
  
  &:last-of-type {
    margin-bottom: 6.4rem;
  }
   
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {    
    & + * {
      margin-top: 12rem;
    }
    
    &:last-of-type {
      margin-bottom: 12rem;
    }
  }
`;

const SpotlightWrap = styled.div`
  position: relative;
  padding: 3.2rem 0 6.4rem;
  
  > * {
    position: relative;
    z-index: 10;
  }
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
    background-color: ${colors.grey100};
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 8rem 7.7vw;
    left: 0;
    transform: translateX(0);
    display: flex;
    
    &::before {
      width: 100%;
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    padding: 8rem 11.2rem;
  }
`;

const SpotlightHeading = styled.h5`
  color: ${colors.red600};
  font-weight: 600;
  margin-bottom: 3.2rem;
  
  &.SpotlightHeading--desktop {
    display: none;
  }
    
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    &.SpotlightHeading--mobile {
      display: none;
    }
    
    &.SpotlightHeading--desktop {
      display: block;
    }
  }
`;

const SpotlightImage = styled.div`
  positon: relative;
  width: 100%;
  
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
  
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  & + * {
    margin-top: 3.2rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 52rem;
    
    & + * {
      margin-top: 2.4rem;
      margin-left: 5.6vw;
    }
  }
`;

const SpotlightContent = styled.div`
  header {
    & + * {
      margin-top: 3.2rem;
    }
  }
  
  h3 {
    font-weight: 400;
    
    & + * {
      margin-top: 0.4rem;
    }
  }
  
  span {
    display: block;
    font-size: 2rem;
  }
  
  p {
    & + * {
      margin-top: 3.2rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 50%;
    
    header {
      & + * {
        margin-top: 2.4rem;
      }
    }
    
    h3 {      
      & + * {
        margin-top: 1.6rem;
      }
    }
    
    p {
      & + * {
        margin-top: 5.6vw;
      }
    }
  }
`;

class EmployeeSpotlight extends Component {
  render() {
    return (
      <SpotlightContainer>
        <Inner>
          <SpotlightWrap>
            <SpotlightHeading className='SpotlightHeading--mobile'>#EmployeeSpotlight</SpotlightHeading>

            {this.props.image && (
              <SpotlightImage>
                <img src={this.props.image.url} alt={this.props.image.alt} />
              </SpotlightImage>
            )}

            <SpotlightContent>
              {(this.props.name || this.props.job_title) && (
                <header>
                  {this.props.name && <h3>{this.props.name}</h3>}
                  {this.props.title && <span>{this.props.title}</span>}
                </header>
              )}

              {this.props.bio && <p>{this.props.bio}</p>}

              <ArrowLink text="Learn More About Careers at Marino" to="/careers"/>
            </SpotlightContent>
          </SpotlightWrap>
        </Inner>
      </SpotlightContainer>
    );
  }
}

export default EmployeeSpotlight;
