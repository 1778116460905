import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner, gridColumns } from 'styles/structure';
import IconQuote from '../../vectors/quote.svg';

const QuoteImageGridContainer = styled.div`
  & + * {
    margin-top: 6.4rem;
  }
  
  &:last-of-type {
    margin-bottom: 6.4rem;
  }
   
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {    
    & + * {
      margin-top: 12rem;
    }
    
    &:last-of-type {
      margin-bottom: 12rem;
    }
  }
`;

const QuoteImageGridWrap = styled.div`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
  }
`;

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  > div {
    ${gridColumns('1/2', 0.8)}
    
    display: block;
    position: relative;
    
    &:first-of-type,
    &:nth-of-type(2) {
      &::after {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 66.6667%;
      }
      
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    &:nth-of-type(2) {
      margin-left: 0.8rem;
    }
    
    &:nth-of-type(3) {
      width: 100%;
      flex: 0 0 100%;
      margin-top: 0.8rem;
    }
  }
  
  img {
    display: block;
    width: 100%;
  }
  
  & + * {
    margin-top: 6.4rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 60%;
    
    > div {
      ${gridColumns('1/2', 3.2)}
      
      &:nth-of-type(2) {
        margin-left: 3.2rem;
      }
      
      &:nth-of-type(3) {
        margin-top: 3.2rem;
      }
    }
  }
`;

const Quote = styled.div`
  svg {
    display: block;
    width: 4.2rem;
    height: 2.9rem;
    fill: ${colors.red600};
    margin-bottom: 1.6rem;
  }

  h4 {
    font-weight: 400;
    line-height: 1.44;
    margin-bottom: 2.4rem;
    max-width: 107.2rem;
  }

  h6 {
    color: ${colors.grey800};
    font-weight: 700;
  }

  span {
    color: ${colors.grey800};
    font-size: 1.6rem;
  }
    
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 40%;
    margin-top: 0;
    margin-left: 5.6vw;
    
    h4 {
      margin-bottom: 3.2rem;
    }

    svg {
      width: 6.8rem;
      height: 4.7rem;
      margin-bottom: 3.2rem;
    }
  }
`;

class QuoteImageGridJustified extends Component {
  render() {
    return (
      <QuoteImageGridContainer>
        <Inner>
          <QuoteImageGridWrap>
            <ImageGrid>
              {this.props.images.slice(0,3).map((field, i) => (
                <div>
                  <img key={i} src={field.image.url} alt={field.image.alt} />
                </div>
              ))}
            </ImageGrid>

            <Quote>
              <IconQuote />

              <h4>{this.props.quote.content}</h4>

              <h6>{this.props.quote.name}</h6>

              {this.props.quote.title && <span>{this.props.quote.title}</span>}
            </Quote>
          </QuoteImageGridWrap>
        </Inner>
      </QuoteImageGridContainer>
    );
  }
}

export default QuoteImageGridJustified;
